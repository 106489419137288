import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Pics from './Pics'
import Map from './Map'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
	{
		path: '/',
		element: <h1 style={{ padding: '0 40px' }}>Hello?</h1>,
	},
	{
		path: '/sp',
		element: <App />,
	},
	{
		path: '/11/12',
		element: <Pics />,
	},
	{
		path: '/08/31',
		element: <Map />,
	},
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
