import React from 'react'

export default function Map() {
	window.location.href = 'https://goo.gl/maps/heKPJ5ZUAaoLCxF6A'
	return (
		<div>
			<h1>Redirecting...</h1>
			<h1>
				Click <a href="https://goo.gl/maps/heKPJ5ZUAaoLCxF6A">Here</a>
			</h1>
		</div>
	)
}
