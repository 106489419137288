import React from 'react'
import './Pics.css'

const D = [
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/1_yqtauj.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/2_mmpcyd.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/3_hx6oru.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/4_xl1fwu.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/5_u5lhii.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/6_nw9mmy.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570045/7_d8rqao.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/8_xvb7xr.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/9_qh2o1q.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/10_vcfz78.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/11_vtqnrv.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/12_fuor44.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/13_ewnurr.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/14_kqdxke.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/15_qiqlwd.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/16_jzvxzc.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570046/17_xghfwf.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570047/18_cjt8kt.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570047/19_rmkjzm.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570047/20_ghwcc0.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682570047/21_vbb1pz.jpg',
	'https://res.cloudinary.com/dcfsxpbrs/image/upload/v1682631841/LOOK_umwdh5.jpg',
]

function Pics() {
	const [width, setWidth] = React.useState<number>(window.innerWidth)

	function handleWindowSizeChange() {
		setWidth(window.innerWidth)
	}
	React.useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

	const isMobile = width <= 1260
	return isMobile ? (
		<h1 style={{ padding: '30vh 20px' }}>
			Please view this on a larger screen... :(
		</h1>
	) : (
		<div style={{ textAlign: 'center' }}>
			<div style={{ height: '100vh', paddingTop: '30vh' }}>
				<h1 style={{ marginBottom: '200px' }}>
					Check my nightstand for gift #3, which will aide you in this next
					challenge...
				</h1>
				<h1 className="scroll">Scroll</h1>
				<h1 className="scroll-line">|</h1>
				<h1 className="scroll-line">|</h1>
				<h1 className="scroll-line">|</h1>
				<h1 className="scroll-arrow">V</h1>
			</div>
			<div style={{ padding: '0 100px', minWidth: '1000px', margin: '0 auto' }}>
				<h1>
					Decode this clue by finding the chapter for each illustration, and
					then use the converstion table to translate the chapter to the
					corresponding code.
				</h1>
				<h1 className="hint">
					You'll end up with a list of 22 numbers between -5 and +6
				</h1>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<h1>§________________§</h1>
						<h1>
							Chapter 1 = <span style={{ color: 'red' }}>0</span>
						</h1>
						<h1>
							Chapter 2 = <span style={{ color: 'red' }}>+1</span>
						</h1>
						<h1>
							Chapter 3 = <span style={{ color: 'red' }}>+2</span>
						</h1>
						<h1>§________________§</h1>
					</div>
					<div>
						<h1>§________________§</h1>
						<h1>
							Chapter 4 = <span style={{ color: 'red' }}>+3</span>
						</h1>
						<h1>
							Chapter 5 = <span style={{ color: 'red' }}>+4</span>
						</h1>
						<h1>
							Chapter 6 = <span style={{ color: 'red' }}>+6</span>
						</h1>
						<h1>§________________§</h1>
					</div>
					<div>
						<h1>§________________§</h1>
						<h1>
							Chapter 7 = <span style={{ color: 'red' }}>-5</span>
						</h1>
						<h1>
							Chapter 8 = <span style={{ color: 'red' }}>-4</span>
						</h1>
						<h1>
							Chapter 9 = <span style={{ color: 'red' }}>-2</span>
						</h1>
						<h1>§________________§</h1>
					</div>
					<div>
						<h1>§________________§</h1>
						<h1>
							Chapter 10 = <span style={{ color: 'red' }}>-1</span>
						</h1>
						<h1>
							Chapter 11 = <span style={{ color: 'red' }}>-2</span>
						</h1>
						<h1>
							Chapter 12 = <span style={{ color: 'red' }}>+1</span>
						</h1>
						<h1>§________________§</h1>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						padding: '10px 40px',
						width: '900px',
						margin: '0 auto',
						justifyContent: 'space-between',
					}}
				>
					{D.map((b, i) => (
						<div key={i} style={{ textAlign: 'center' }}>
							<h1 style={{ marginBottom: 0, lineHeight: '20px' }}>{i + 1}</h1>
							<img
								src={b}
								alt="salvi"
								style={{
									height: '384px',
									width: '216px',
									marginBottom: '40px',
								}}
							/>
						</div>
					))}
				</div>
				<h1>
					Once complete, <a href="https://ean.dev/sp">click here</a> to
					continue...
				</h1>
			</div>
		</div>
	)
}

export default Pics
